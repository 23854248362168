import { useEffect, useRef } from 'react';

export const useOnClickOutside = ({ onClick }: { onClick: () => void }) => {
  const ref = useRef<HTMLElement>(null);
  useEffect(() => {
    const handleClickOutside = (event: any) => {
      if (ref.current && !ref.current.contains(event.target)) {
        onClick();
      }
    };
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [ref, onClick]);
  return ref;
};
