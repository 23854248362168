import React, { RefObject } from 'react';
import { motion } from 'framer-motion';
import { Link } from 'gatsby';
import { Icon } from '../icon/Icon';

const SIDEBAR_ANIMATION_DURATION = 0.3;
const SIDEBAR_WIDTH = '85vw';
export const Sidebar: React.FC<{
  isOpen: boolean;
  setIsOpen: (arg: boolean) => void;
  sidebarRef: RefObject<HTMLElement>;
  pages: { label: string; to: string }[];
}> = ({ isOpen, setIsOpen, sidebarRef, pages }) => {
  return (
    <motion.div
      initial={{ translateX: SIDEBAR_WIDTH }}
      transition={{ duration: SIDEBAR_ANIMATION_DURATION }}
      animate={isOpen ? { translateX: 0 } : { translateX: SIDEBAR_WIDTH }}
      className="mobile-only absolute top-0 right-0 h-screen w-sidebar bg-gray-sidebarDark border-l border-black px-3 z-10"
    >
      <aside ref={sidebarRef}>
        <button onClick={() => setIsOpen(false)}>
          <Icon
            name="close"
            alt="close sidebar"
            className="absolute top-2 right-2"
          />
        </button>

        <div className="flex flex-col mt-10">
          {pages.map((page) => (
            <Link
              to={page.to}
              className="text-white font-semibold mt-2.5"
              key={page.label}
            >
              {page.label}
            </Link>
          ))}
        </div>
      </aside>
    </motion.div>
  );
};
