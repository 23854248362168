import closeIcon from './close.svg';
import menuBlackIcon from './menuBlack.svg';
import menuWhiteIcon from './menuWhite.svg';
import mutedIcon from './muted.svg';
import pauseIcon from './pause.svg';
import playIcon from './play.svg';
import volumeIcon from './volume.svg';

export const assets: Record<string, any> = {
  close: closeIcon,
  menuBlack: menuBlackIcon,
  menuWhite: menuWhiteIcon,
  muted: mutedIcon,
  pause: pauseIcon,
  play: playIcon,
  volume: volumeIcon,
};
