import React, { useState } from 'react';

import { motion } from 'framer-motion';
import { routes } from './routes';
import { classnames } from '../../utils/classnames';
import { useOnClickOutside } from '../../hooks/useOnClickOutside';
import blackLogo from './assets/blackLogo.png';
import whiteLogo from './assets/whiteLogo.png';
import { Link } from 'gatsby';
import { Icon } from '../icon/Icon';
import { Sidebar } from './Sidebar';
import { Helmet } from 'react-helmet';

const HEADERS_TRANSLATE_Y = -80;

export const Layout: React.FC<{
  whiteBackground?: boolean;
  headerTransition?: boolean;
  isPlayerVisible?: boolean;
}> = ({
  children,
  whiteBackground = false,
  headerTransition = false,
  isPlayerVisible = true,
}) => {
  const pages = [
    { label: 'ACCUEIL', to: routes.home },
    { label: 'RÉALISATIONS', to: routes.realizations },
    { label: 'CONTACT', to: routes.contact },
  ];
  const [isSidebarOpen, setIsSidebarOpen] = useState(false);
  const sidebarRef = useOnClickOutside({
    onClick: () => {
      setIsSidebarOpen(false);
    },
  });
  return (
    <div
      className={classnames({
        'min-h-screen h-something relative overflow-x-hidden': true,
        'bg-black': !whiteBackground,
      })}
    >
      <Helmet>
        <meta charSet="utf-8" />
        <title>Evrox</title>
      </Helmet>

      <motion.div
        initial={{ translateY: headerTransition ? HEADERS_TRANSLATE_Y : 0 }}
        transition={{ duration: 2, delay: 3 }}
        animate={{ translateY: 0 }}
        className={classnames({
          'z-10 relative bg-gradient-to-b from-black to-transparent':
            headerTransition,
        })}
      >
        <header
          className={classnames({
            'flex flex-row w-full justify-center items-center p-2.5 md:p-4':
              true,
            'text-white': !whiteBackground,
          })}
        >
          <motion.img
            alt="company logo"
            src={whiteBackground ? blackLogo : whiteLogo}
            className="mr-auto w-15 z-10"
            animate={{ opacity: isPlayerVisible ? 1 : 0 }}
          />
          <div className="laptop-only mr-auto">
            <div className="flex flex-row">
              {pages.map((item) => (
                <Link to={item.to} key={item.label} className="mx-4 z-20">
                  <motion.div animate={{ opacity: isPlayerVisible ? 1 : 0 }}>
                    {item.label}
                  </motion.div>
                </Link>
              ))}
            </div>
          </div>
          <div className="mobile-only">
            <div className="flex items-center">
              <button
                onClick={() => setIsSidebarOpen(true)}
                className="focus:outline-none z-10"
              >
                <Icon
                  name={whiteBackground ? 'menuBlack' : 'menuWhite'}
                  alt="toggle menu"
                />
              </button>
            </div>
          </div>
        </header>
      </motion.div>

      <main className="flex flex-col items-center h-mobileMain md:h-laptopMain w-full">
        {children}
        <Sidebar
          isOpen={isSidebarOpen}
          setIsOpen={setIsSidebarOpen}
          sidebarRef={sidebarRef}
          pages={pages}
        />
      </main>
    </div>
  );
};
